<template>
  <div style="padding:10px;">
    <v-btn
      @click="onBack"
      color="primary"
      style="margin: 10px;"
    >
      volver
    </v-btn>
    <css-editor
      v-if="id"
      :config="prepareCssEditor(id)"
      :onSave="prepareURL"
    />
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="3"
        style="padding:5px;"
      >
          <v-select
            v-model="typeID"
            :items="types"
            outlined
            label="Origen datos"
            itemText="name"
            itemValue="id"
            hide-details
            @change="prepareURL"
          />
      </v-col>
      <v-col
        cols="12"
        sm="3"
        style="padding:5px;"
      >
        <id-selector
          :label="'ID'"
          :type="typeID === 'cmdviewcontentid' ? 'cmdviewcontentevents' : typeID === 'dviewid' ? 'dviewcontentOldID' : typeID === 'id' ? 'bookingID' : typeID"
          :value="id"
          :onChange="(v) => {
            id = v
            prepareURL()
          }"
        />
        <!--v-text-field
          v-model="id"
          label="ID"
          @input="prepareURL"
          hide-details
          outlined
        /-->
      </v-col>
      <v-col
        cols="12"
        sm="3"
        style="padding:5px;"
      >
        <v-text-field
          v-model="category"
          label="CategoryID"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="3"
        style="padding:5px;"
      >
          <v-autocomplete
            v-model="locale"
            :items="languages"
            outlined
            chips
            small-chips
            label="Idioma"
            itemText="NormalName"
            itemValue="ID"
            hide-details
            @change="prepareURL"
          />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="addDays"
          label="A�adir d�a (0-hoy, 1-ma�ana)"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="zoom"
          label="Zoom"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="bgColor"
          label="Color fondo"
          :onChange="handleChangeBGColor"
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="color1"
          label="Color 1"
          :onChange="handleChangeColor1"
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <color-picker-item
          :value="color2"
          label="Color 2"
          :onChange="handleChangeColor2"
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-checkbox 
          v-model="paged" 
          hide-details
          label="paginado"
          @change="prepareURL"
        />
      </v-col>
      <v-col
        v-if="paged"
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="rows"
          label="Filas"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        v-if="paged"
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="interval"
          label="Interval"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        style="text-align:center;padding:20px;"
      >
        <copy-url :url="url" />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
      <v-col
        cols="12"
        sm="12"
        lg="6"
        style="text-align: center !important;"
        class="preview"
      >
          <preview 
            v-if="parentWidth && url"
            :url="url"
            locale="es"
            :size="{
              w: 1920,
              h: 1080
            }"
            :parentWidth="parentWidth"
          />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
    </v-row>
  </div>
</template>
<script>
import utilsService from '@/services/utils'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
import IdSelector from './IdSelector'
import Preview from './Preview'
import CopyUrl from './CopyUrl'
import CssEditor from './cssEditor/Index'
export default {
  components: {
    IdSelector,
    Preview,
    CopyUrl,
    ColorPickerItem,
    CssEditor,
  },
  props: {
    onBack: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    typeID: 'id',
    url: null,
    zoom: 100,
    id: null,//909c0748-f9b0-4e07-90ea-4860ebdd558b',
    locale: 'es',
    bgColor: null,
    color1: null,
    color2: null,
    addDays: 0,
    category: null,
    parentWidth: null,
    rows: null,
    paged: false,
    interval: 6000,
  }),
  computed: {
    types () {
      return [
        {
          id: 'dviewid',
          name: 'DviewcontentID [OLD]'
        },
        {
          id: 'id',
          name: 'BookingID'
        },
        {
          id: 'cmdviewcontentid',
          name: 'CM DviewcontentID EVENT [NEW]'
        },
      ]
    },
    languages () {
      return utilsService.getAllLanguages()
    },
  },
  watch: {
    typeID () {
      this.id = null
      this.prepareURL()
    },
  },
  mounted () {
    setTimeout(() => {
      this.parentWidth = document.getElementsByClassName('preview')[0].offsetWidth - 0
    }, 1000)
    this.prepareURL()
  },
  methods: {
    handleChangeBGColor (v) {
      this.bgColor = v
      this.prepareURL()
    },
    handleChangeColor1 (v) {
      this.color1 = v
      this.prepareURL()
    },
    handleChangeColor2 (v) {
      this.color2 = v
      this.prepareURL()
    },
    prepareURL () {
      if (!this.id) {
        this.url = null
        return
      }
      this.id = this.id.split(' ').pop('')
      this.url = `https://widgets.dviewcontent.com/activity-week-card/?`
        .concat(`&${this.typeID}=${this.id}`)
        .concat(`&locale=${this.locale}`)
        .concat(`&addDays=${this.addDays ? this.addDays : 0}`)
        .concat(this.zoom && this.zoom.length > 0 && this.zoom !== '100' ? `&zoom=${this.zoom}`: '')
        .concat(this.bgColor && this.bgColor.length > 0 ? `&bgcolor=${this.bgColor.replace('#','')}`: '')
        .concat(this.color1 && this.color1.length > 0 ? `&color1=${this.color1.replace('#','')}`: '')
        .concat(this.color2 && this.color2.length > 0 ? `&color2=${this.color2.replace('#','')}`: '')
        .concat(this.category && this.category.length > 0 ? `&category=${this.category}`: '')
        .concat(this.rows && this.rows.length > 0 ? `&rows=${this.rows}`: '')
        .concat(this.paged ? `&carousel=${this.paged}`: '')
        .concat(this.paged && this.interval && this.interval.length > 0 ? `&interval=${this.interval}`: '')
        .concat(`&tmp=${new Date().toISOString().split('.').join('').split(':').join('')}`)
    },
    prepareCssEditor (v) {
      if (!v) return
      const config = []
      config['dviewid'] = {
        id: v,
        type: 'dviewcontentOLD',
        template: 'activity-week-card',
        previewURL: this.url,
      }
      config['id'] = {
        id: v,
        type: 'booking',
        template: 'activity-week-card',
        previewURL: this.url,
      }
      return config[this.typeID]
    },
  },
}
</script>

